import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormGroup,
  Card,
  CardHeader,
  Container,
} from "shards-react";
import Button from '@material-ui/core/Button';
import { getApiUrl } from "./Global";

class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminEmail: '',
      adminUserName: '',
      adminPassword: '',
      dbHost: '',
      dbName: '',
      dbUserName: '',
      dbPassword: '',
    };

    this.save = this.save.bind(this);
    this.handleAdminEmail = this.handleAdminEmail.bind(this);
    this.handleAdminUserName = this.handleAdminUserName.bind(this);
    this.handleAdminPassword = this.handleAdminPassword.bind(this);
    this.handleDBHost = this.handleDBHost.bind(this);
    this.handleDBName = this.handleDBName.bind(this);
    this.handleDBPassword = this.handleDBPassword.bind(this);
    this.handleDBUserName = this.handleDBUserName.bind(this);
    this.getSetting = this.getSetting.bind(this);
  }

  getSetting() {
    fetch(getApiUrl() + 'get_setting', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          this.setState({
            adminEmail: json.setting.email,
            adminUserName: json.setting.user_name,
            adminPassword: json.setting.password,
            dbHost: json.setting.db_host,
            dbName: json.setting.db_name,
            dbUserName: json.setting.db_user_name,
            dbPassword: json.setting.db_password,
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    if(localStorage.getItem("login_success") !== "true")
      this.props.history.push("login");
    else
      this.getSetting();
  }

  handleAdminEmail(event) {
    this.setState({
      adminEmail: event.target.value,
    })
  }

  handleAdminPassword(event) {
    this.setState({
      adminPassword: event.target.value,
    })
  }

  handleAdminUserName(event) {
    this.setState({
      adminUserName: event.target.value,
    })
  }

  handleDBHost(event) {
    this.setState({
      dbHost: event.target.value,
    })
  }

  handleDBName(event) {
    this.setState({
      dbName: event.target.value,
    })
  }

  handleDBUserName(event) {
    this.setState({
      dbUserName: event.target.value,
    })
  }

  handleDBPassword(event) {
    this.setState({
      dbPassword: event.target.value,
    })
  }

  save() {
    fetch(getApiUrl() + 'save_setting', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.adminEmail,
        user_name: this.state.adminUserName,
        password: this.state.adminPassword,
        db_host: this.state.dbHost,
        db_name: this.state.dbName,
        db_user_name: this.state.dbUserName,
        db_password: this.state.dbPassword,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          localStorage.setItem("ppvd_token", json.token);
          alert('Success');
        }
        else
          alert("Failed.");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <Container fluid className="main-content-container p-4">
        <Row className="justify-content-center">
          <Col>
            <Card small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">General Settings</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Email Address</label>
                          <Col md={9}>
                            <FormInput type="email" onChange={this.handleAdminEmail} value={this.state.adminEmail}/>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Admin UserName</label>
                          <Col md={9}>
                            <FormInput onChange={this.handleAdminUserName} value={this.state.adminUserName}/>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Admin Password</label>
                          <Col md={9}>
                            <FormInput type="password" onChange={this.handleAdminPassword}  value={this.state.adminPassword}/>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
              <CardHeader className="border-bottom">
                <h6 className="m-0">Database Settings</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Database Host</label>
                          <Col md={9}>
                            <FormInput onChange={this.handleDBHost} value={this.state.dbHost}/>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Database Name</label>
                          <Col md={9}>
                            <FormInput onChange={this.handleDBName} value={this.state.dbName}/>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Database UserName</label>
                          <Col md={9}>
                            <FormInput onChange={this.handleDBUserName}  value={this.state.dbUserName}/>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Database Password</label>
                          <Col md={9}>
                            <FormInput type="password" onChange={this.handleDBPassword}  value={this.state.dbPassword}/>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col md={11} className="text-right">
                            <Button variant="contained" color="primary" className="save-button" onClick={this.save}>Save</Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Setting;
