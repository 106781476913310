import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormGroup,
  Card,
  CardHeader,
  Container,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import { getApiUrl } from "./Global";

class Network extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      network: '',
      token1: '',
      token2: '',
      token3: '',
      token4: '',
      token5: '',
      e_network: '',
      e_token1: '',
      e_token2: '',
      e_token3: '',
      e_token4: '',
      e_token5: '',
      networkList: [],
      selectedIndex: 0,
    };

    this.handleNetwork = this.handleNetwork.bind(this);
    this.handleToken1 = this.handleToken1.bind(this);
    this.handleToken2 = this.handleToken2.bind(this);
    this.handleToken3 = this.handleToken3.bind(this);
    this.handleToken4 = this.handleToken4.bind(this);
    this.handleToken5 = this.handleToken5.bind(this);
    this.e_handleNetwork = this.e_handleNetwork.bind(this);
    this.e_handleToken1 = this.e_handleToken1.bind(this);
    this.e_handleToken2 = this.e_handleToken2.bind(this);
    this.e_handleToken3 = this.e_handleToken3.bind(this);
    this.e_handleToken4 = this.e_handleToken4.bind(this);
    this.e_handleToken5 = this.e_handleToken5.bind(this);
    this.addNetwork = this.addNetwork.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.editNetwork = this.editNetwork.bind(this);
    this.getNetworkList = this.getNetworkList.bind(this);
  }

  getNetworkList() {
    fetch(getApiUrl() + 'get_platforms', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      }
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          this.setState({
            networkList: json.platforms,
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    if(!localStorage.getItem("ppvd_token"))
      this.props.history.push("login");
    else
      this.getNetworkList();
  }

  editNetwork() {
    this.toggle();
    fetch(getApiUrl() + 'edit_platform', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        platform_name: this.state.e_network,
        sub1: this.state.e_token1,
        sub2: this.state.e_token2,
        sub3: this.state.e_token3,
        sub4: this.state.e_token4,
        sub5: this.state.e_token5,
        id: this.state.networkList[this.state.selectedIndex].id,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          this.getNetworkList();
          alert("Success");
        }
        else
          alert("Failed");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  onDelete(index) {
    if(window.confirm("Are you want to delete this platform?") === true) {
      fetch(getApiUrl() + 'delete_platform/' + this.state.networkList[index].id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'token': localStorage.getItem("ppvd_token"),
        }
      })
        .then(response => response.json())
        .then(json => {
          if ('success' in json && json.success) {
            var temp = this.state.networkList;
            temp.splice(index, 1);
            this.setState({
              networkList: temp
            });
            alert("Success");
          }
          else
            alert("Failed");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  onEdit(index) {
    this.setState({
      selectedIndex: index,
      e_network: this.state.networkList[index].name,
      e_token1: this.state.networkList[index].sub1,
      e_token2: this.state.networkList[index].sub2,
      e_token3: this.state.networkList[index].sub3,
      e_token4: this.state.networkList[index].sub4,
      e_token5: this.state.networkList[index].sub5,
    }, this.toggle());
  }

  handleNetwork(event) {
    this.setState({
      network: event.target.value,
    })
  }

  handleToken1(event) {
    this.setState({
      token1: event.target.value,
    })
  }

  handleToken2(event) {
    this.setState({
      token2: event.target.value,
    })
  }

  handleToken3(event) {
    this.setState({
      token3: event.target.value,
    })
  }

  handleToken4(event) {
    this.setState({
      token4: event.target.value,
    })
  }

  handleToken5(event) {
    this.setState({
      token5: event.target.value,
    })
  }

  e_handleNetwork(event) {
    this.setState({
      e_network: event.target.value,
    })
  }

  e_handleToken1(event) {
    this.setState({
      e_token1: event.target.value,
    })
  }

  e_handleToken2(event) {
    this.setState({
      e_token2: event.target.value,
    })
  }

  e_handleToken3(event) {
    this.setState({
      e_token3: event.target.value,
    })
  }

  e_handleToken4(event) {
    this.setState({
      e_token4: event.target.value,
    })
  }

  e_handleToken5(event) {
    this.setState({
      e_token5: event.target.value,
    })
  }

  addNetwork() {
    fetch(getApiUrl() + 'add_platform', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        platform_name: this.state.network,
        sub1: this.state.token1,
        sub2: this.state.token2,
        sub3: this.state.token3,
        sub4: this.state.token4,
        sub5: this.state.token5,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          this.getNetworkList();
          this.setState({
            network: '',
            token1: '',
            token2: '',
            token3: '',
            token4: '',
            token5: '',
          })
          alert("Success");
        }
        else
          alert("Failed");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <Container fluid className="main-content-container p-4">
        <Row className="justify-content-center">
          <Col>
            <Card small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">Add Network</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Add Network</label>
                          <Col md={9}>
                            <FormInput onChange={this.handleNetwork} value={this.state.network} />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Sub1 Token</label>
                          <Col md={9}>
                            <FormInput onChange={this.handleToken1} value={this.state.token1} />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Sub2 Token</label>
                          <Col md={9}>
                            <FormInput onChange={this.handleToken2} value={this.state.token2} />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Sub3 Token</label>
                          <Col md={9}>
                            <FormInput onChange={this.handleToken3} value={this.state.token3} />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Sub4 Token</label>
                          <Col md={9}>
                            <FormInput onChange={this.handleToken4} value={this.state.token4} />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Sub5 Token</label>
                          <Col md={9}>
                            <FormInput onChange={this.handleToken5} value={this.state.token5} />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col md={11} className="text-right">
                            <Button variant="contained" color="primary" className="save-button" onClick={this.addNetwork}>Add</Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
        {/* Network Table */}
        <Row className="mt-3">
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Network List</h6>
              </CardHeader>
              <CardBody className="p-3 pb-3">
                <Table className="custom-table" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className="custom-header-cell">Network</TableCell>
                      <TableCell align="center" className="custom-header-cell">Sub1 Token</TableCell>
                      <TableCell align="center" className="custom-header-cell">Sub2 Token</TableCell>
                      <TableCell align="center" className="custom-header-cell">Sub3 Token</TableCell>
                      <TableCell align="center" className="custom-header-cell">Sub4 Token</TableCell>
                      <TableCell align="center" className="custom-header-cell">Sub5 Token</TableCell>
                      <TableCell align="center" className="custom-header-cell">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.networkList.map((row, index) => (
                      <TableRow hover key={index}>
                        <TableCell align="center" className="custom-cell">{row.name}</TableCell>
                        <TableCell align="center" className="custom-cell">{row.sub1}</TableCell>
                        <TableCell align="center" className="custom-cell">{row.sub2}</TableCell>
                        <TableCell align="center" className="custom-cell">{row.sub3}</TableCell>
                        <TableCell align="center" className="custom-cell">{row.sub4}</TableCell>
                        <TableCell align="center" className="custom-cell">{row.sub5}</TableCell>
                        <TableCell align="center" className="custom-cell">
                          <IconButton size='small' onClick={this.onEdit.bind(null, index)}>
                            <EditIcon color="primary" />
                          </IconButton>
                          <IconButton size='small' onClick={() => this.onDelete(index)}>
                            <DeleteIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {this.state.networkList.length === 0 &&
                      <TableRow>
                        <TableCell align="center" className="custom-cell" colSpan={7}>No Network</TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal open={this.state.open} toggle={this.toggle}>
          <ModalHeader>Edit Network</ModalHeader>
          <ModalBody>
            <Row className="justify-content-center">
              <Col>
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Row form>
                            <label className="col-form-label label-responsive">Network</label>
                            <FormInput value={this.state.e_network} onChange={this.e_handleNetwork} />
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row form>
                            <label className="col-form-label label-responsive">Sub1 Token</label>
                            <FormInput value={this.state.e_token1} onChange={this.e_handleToken1} />
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row form>
                            <label className="col-form-label label-responsive">Sub2 Token</label>
                            <FormInput value={this.state.e_token2} onChange={this.e_handleToken2} />
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row form>
                            <label className="col-form-label label-responsive">Sub3 Token</label>
                            <FormInput value={this.state.e_token3} onChange={this.e_handleToken3} />
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row form>
                            <label className="col-form-label label-responsive">Sub4 Token</label>
                            <FormInput value={this.state.e_token4} onChange={this.e_handleToken4} />
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row form>
                            <label className="col-form-label label-responsive">Sub5 Token</label>
                            <FormInput value={this.state.e_token5} onChange={this.e_handleToken5} />
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col md={12} className="text-right">
                              <Button variant="contained" color="primary" className="save-button" onClick={this.editNetwork}>Save</Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}
export default Network;
