import React from "react";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  Badge,
} from "shards-react";
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {getApiUrl} from "./Global";

const typeList = {
  lp: "Landing Page",
  redirect: "Direct Link",
  frame: 'Frame',
  meta: "Meta Refresh",
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleted: false,
      platforms: [],
      campaigns: [],
      dateTotal: [
        {
          date: "Today",
          color: '#1BB394',
          title: "0",
          description: "Today Summary Impressions",
        },
        {
          date: "Yesterday",
          color: '#23C6C8',
          title: "0",
          description: "Yesterday Summary Impressions"
        },
        {
          date: "This week",
          color: '#F8AC59',
          title: "0",
          description: "This week Summary Impressions"
        },
        {
          date: "Last week",
          color: '#ED5565',
          title: "0",
          description: "Last week Summary Impressions"
        },
        {
          date: "Last 7 days",
          color: '#1BB394',
          title: "0",
          description: "Last 7 days Summary Impressions"
        },
        {
          date: "This month",
          color: '#23C6C8',
          title: "0",
          description: "This month Summary Impressions"
        },
        {
          date: "Last month",
          color: '#F8AC59',
          title: "0",
          description: "Last month Summary Impressions"
        },
        {
          date: "All",
          color: '#ED5565',
          title: "0",
          description: "All Summary Impressions"
        },
      ],
    };

    this.switchShowCampaign = this.switchShowCampaign.bind(this);
    this.onItemSelected = this.onItemSelected.bind(this);
    this.onItemEdit = this.onItemEdit.bind(this);
    this.onItemTest = this.onItemTest.bind(this);
    this.onItemDelete = this.onItemDelete.bind(this);
    this.onItemSwitch = this.onItemSwitch.bind(this);
    this.onItemCloak = this.onItemCloak.bind(this);
    this.getData = this.getData.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  refresh() {
    this.getData();
  }

  switchShowCampaign() {
    this.setState({
      showDeleted: !this.state.showDeleted
    });
  }

  onItemSelected(row) {
    if(row.type === "lp")
      this.props.history.push('/lpreport/' + row.name);
    else
      this.props.history.push('/report/' + row.name);
  }

  onItemEdit(e, row) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if(row.type === "lp")
      this.props.history.push('/edit-lpcampaign/' + row.name);
    else
      this.props.history.push('/edit-campaign/' + row.name);
  }

  onItemTest(e, row) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    window.open(getApiUrl() + 'link?file=' + row.name, "_blank");
    this.getData();
  }

  onItemDelete(e, row) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if(window.confirm("Are you want to delete this campaign?") === true) {
      fetch(getApiUrl() + 'delete_campaign', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'token': localStorage.getItem("ppvd_token"),
        },
        body: JSON.stringify({
          'file': row.name,
          'type': row.type,
        })
      })
        .then(response => response.json())
        .then(json => {
          if ('success' in json && json.success) {
            var temp = this.state.campaigns;
            var index = temp.indexOf(row);
            if (index > -1)
              temp.splice(index, 1);
            this.setState({
              campaigns: temp,
            })
          }
          else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  onItemSwitch(e, row) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    fetch(getApiUrl() + 'switch_campaign', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        'file': row.name,
        'hidden': row.hidden === 1 ? 0 : 1,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          var temp = this.state.campaigns;
          var index = temp.indexOf(row);
          temp[index].hidden = row.hidden === 1 ? 0 : 1;
          this.setState({
            campaigns: temp,
          })
        }
        else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onItemCloak(e, row) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    fetch(getApiUrl() + 'switch_cloak_campaign', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        'file': row.name,
        'cloaked': row.cloaked === 1 ? 0 : 1,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          var temp = this.state.campaigns;
          var index = temp.indexOf(row);
          temp[index].cloaked = row.cloaked === 1 ? 0 : 1;
          this.setState({
            campaigns: temp,
          })
        }
        else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getData() {
    fetch(getApiUrl() + 'get_homedata', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      }
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          var pStr = [
            "totalClicksToday",
            "totalClicksYesterday",
            "totalClicksThisWeek",
            "totalClicksLastWeek",
            "totalClicksLast7Days",
            "totalClicksThisMonth",
            "totalClicksLastMonth",
            "totalClicksAll"
          ];
          var tmpDateTotal = this.state.dateTotal;
          for(var i = 0; i < tmpDateTotal.length; i++) {
            tmpDateTotal[i].title = json.increment[pStr[i]];
          }
          this.setState({
            dateTotal: tmpDateTotal,
            platforms: json.platforms,
            campaigns: json.campaigns,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    if(!localStorage.getItem("ppvd_token"))
      this.props.history.push("login");
    else
      this.getData();
  }

  render() {
    return (
      <Container fluid className="main-content-container p-4">
        <Row className="justify-content-center">
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Navigation</h6>
              </CardHeader>
              <CardBody className="p-2 text-center">
                <Button variant="contained" color="primary" className="save-button m-1" onClick={this.switchShowCampaign}>{this.state.showDeleted ? 'Hide Inactive Campaigns' : 'Show All Campaigns'}</Button>
                <Button variant="contained" color="primary" className="save-button m-1" onClick={this.refresh}>Refresh</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          {this.state.dateTotal.map((data, idx) => (
            <Col lg="3" md="6" sm="12" className="mb-2" key={idx}>
              <Card small className="card-post card-post--1">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">{data.date}</h6>
                  <Badge
                    pill
                    className="card-post__category"
                    style={{backgroundColor: data.color}}
                  >
                    {data.date}
                  </Badge>
                </CardHeader>
                <CardBody className="p-3">
                  <h3>{numberWithCommas(data.title)}</h3>
                  <span className="text-muted">{data.description}</span>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <Row className="justify-content-center mt-5">
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">PPV Summary</h6>
              </CardHeader>
              <CardBody className="pl-4 pr-4">
              {this.state.platforms.map((table, index) => (
                <Table className={'custom-table ' + (index > 0 ? 'mt-4' : '')} aria-label="simple table" key={index}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan="5" style={{ backgroundColor: '#2F4050', padding: '10px', }} className="text-center"><h6 style={{color: 'white'}}>{table.name}</h6></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="custom-cell"><h6 style={{fontWeight: 'bold'}}>ID</h6></TableCell>
                      <TableCell align="center" className="custom-cell"><h6 style={{fontWeight: 'bold'}}>Campaign Name</h6></TableCell>
                      <TableCell align="center" className="custom-cell"><h6 style={{fontWeight: 'bold'}}>Today's Impressions</h6></TableCell>
                      <TableCell align="center" className="custom-cell"><h6 style={{fontWeight: 'bold'}}>Type</h6></TableCell>
                      <TableCell align="center" className="custom-cell"><h6 style={{fontWeight: 'bold'}}>Actions</h6></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.campaigns.map((row, index) => (
                      ((row.hidden === 0 || (this.state.showDeleted && row.hidden === 1)) && row.platform_name === table.name) && 
                      <TableRow hover key={index}  onClick={this.onItemSelected.bind(null, row)} className="custom-row-cursor">
                        <TableCell align="center" className={'custom-cell ' + (row.hidden ? 'deleted-cell' : 'active-cell')}>{row.id}</TableCell>
                        <TableCell align="center" className={'custom-cell ' + (row.hidden ? 'deleted-cell' : 'active-cell')}>{row.name}</TableCell>
                        <TableCell align="center" className={'custom-cell ' + (row.hidden ? 'deleted-cell' : 'active-cell')}>{row.today_impression}</TableCell>
                        <TableCell align="center" className={'custom-cell ' + (row.hidden ? 'deleted-cell' : 'active-cell')}>{typeList[row.type]}</TableCell>
                        <TableCell align="center" className={'custom-cell '}>
                          <Button className="custom-action-button" onClick={(event) => this.onItemEdit(event, row)}>edit</Button>
                          <Button className="custom-action-button" >report</Button>
                          <Button className="custom-action-button" onClick={(event) => this.onItemTest(event, row)}>test</Button>
                          <Button className="custom-action-button" onClick={(event) => this.onItemDelete(event, row)}>delete</Button>
                          <Button className="custom-action-button" onClick={(event) => this.onItemSwitch(event, row)}>{row.hidden === 0 ? 'deactive' : 'active'}</Button>
                          <Button className="custom-action-button" onClick={(event) => this.onItemCloak(event, row)}>{row.cloaked ===0 ? 'uncloak' : 'cloak'}</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Home;
