import React from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  Container,
  ListGroup,
  FormGroup,
  FormInput,
  ListGroupItem,
  FormTextarea,
} from "shards-react";
import Button from '@material-ui/core/Button';
import { getApiUrl } from './Global';
import MySnackbarContentWrapper from '../components/snackbar/MySnackbarContentWrapper';
import {CopyToClipboard} from 'react-copy-to-clipboard';

class EditCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInactive: false,
      cpv: '',
      group: '',
      offer: '',
      commission: '',
      offerUrl: '',
      offerWeight: '',
      offerList: [],
      platform: {},
    };
    this.offerWeightSum = 0;

    this.switchShowInactive = this.switchShowInactive.bind(this);
    this.handleCPV = this.handleCPV.bind(this);
    this.handleGroup = this.handleGroup.bind(this);
    this.handleOffer = this.handleOffer.bind(this);
    this.handleCommission = this.handleCommission.bind(this);
    this.handleOfferUrl = this.handleOfferUrl.bind(this);
    this.handleOfferWeight = this.handleOfferWeight.bind(this);
    this.handleChangeOfferListCommission = this.handleChangeOfferListCommission.bind(this);
    this.handleChangeOfferListName = this.handleChangeOfferListName.bind(this);
    this.handleChangeOfferListUrl = this.handleChangeOfferListUrl.bind(this);
    this.handleChangeOfferListWeight = this.handleChangeOfferListWeight.bind(this);
    this.goReport = this.goReport.bind(this);
    this.settingsSave = this.settingsSave.bind(this);
    this.offerCreate = this.offerCreate.bind(this);
    this.getData = this.getData.bind(this);
    this.showAllOfferItems = this.showAllOfferItems.bind(this);
    this.hideAllOfferItems = this.hideAllOfferItems.bind(this);
    this.onSaveOfferItem = this.onSaveOfferItem.bind(this);
    this.onSwitchOfferItem = this.onSwitchOfferItem.bind(this);
    this.onDeleteOfferItem = this.onDeleteOfferItem.bind(this);
    this.splitOfferItems = this.splitOfferItems.bind(this);
    this.onTestLink = this.onTestLink.bind(this);
  }

  componentDidMount() {
    if (!localStorage.getItem("ppvd_token"))
      this.props.history.push("login");
    else
      this.getData();
  }

  onTestLink() {
    window.open(getApiUrl() + 'link?file=' + this.props.match.params.file + '&sub1=' + this.state.platform.sub1 + '&sub2=' + this.state.platform.sub2 + '&sub3=' + this.state.platform.sub3 + '&sub4=' + this.state.platform.sub4 + '&sub5=' + this.state.platform.sub5);
  }

  splitOfferItems() {
    var avWeight = this.offerWeightSum / this.state.offerList.length;
    fetch(getApiUrl() + 'split_lp_items', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        'file': this.props.match.params.file,
        'weight': avWeight,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  showAllOfferItems() {
    fetch(getApiUrl() + 'show_all_lp_items', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        'file': this.props.match.params.file,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          var temp = this.state.offerList;
          for (var i = 0; i < temp.length; i++)
            temp[i].hidden = 0;
          this.setState({
            offerList: temp
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  hideAllOfferItems() {
    fetch(getApiUrl() + 'hide_all_lp_items', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        'file': this.props.match.params.file,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          var temp = this.state.offerList;
          for (var i = 0; i < temp.length; i++)
            temp[i].hidden = 1;
          this.setState({
            offerList: temp
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getData() {
    fetch(getApiUrl() + 'get_dr_campaign_settings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        'file': this.props.match.params.file,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          this.setState({
            offerList: json.settings,
            platform: json.platform,
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  settingsSave() {
    if (!this.state.cpv || isNaN(this.state.cpv) === true) {
      alert("Please input CPV correctly");
      return;
    }
    if (!this.state.group) {
      alert("Please input group name correctly.");
      return;
    }
    fetch(getApiUrl() + 'save_campaign_setting', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        'file': this.props.match.params.file,
        'cpc': this.state.cpv,
        'group': this.state.group,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          alert("Success");
          this.getData();
        }
        else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  offerCreate() {
    if (!this.state.offer) {
      alert("Please input Offer name correctly.");
      return;
    }
    if (!this.state.commission || isNaN(this.state.commission) === true) {
      alert("Please input offer commission correctly");
      return;
    }
    if (!this.state.offerUrl) {
      alert("Please input Offer URL correctly.");
      return;
    }
    if (!this.state.offerWeight || isNaN(this.state.offerWeight) === true) {
      alert("Please input Offer weight correctly");
      return;
    }
    fetch(getApiUrl() + 'add_direct_page', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        'file': this.props.match.params.file,
        'title': this.state.offer,
        'weight': this.state.offerWeight,
        'url': this.state.offerUrl,
        'commission': this.state.commission,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          alert("Success");
          this.getData();
        }
        else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onSaveOfferItem(index) {
    fetch(getApiUrl() + 'save_direct_item', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        'file': this.props.match.params.file,
        'title': this.state.offerList[index].title,
        'weight': this.state.offerList[index].weight,
        'url': this.state.offerList[index].url,
        'id': this.state.offerList[index].id,
        'commission': this.state.offerList[index].commission,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          alert("Success");
        }
        else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onSwitchOfferItem(index) {
    fetch(getApiUrl() + 'hide_lp_item', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        'file': this.props.match.params.file,
        'hidden': this.state.offerList[index].hidden === 1 ? 0 : 1,
        'id': this.state.offerList[index].id,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          var temp = this.state.offerList;
          temp[index].hidden = temp[index].hidden === 0 ? 1 : 0;
          this.setState({
            offerList: temp
          });
        }
        else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onDeleteOfferItem(index) {
    if(window.confirm("Are you want to delete this data?") === true) {
      fetch(getApiUrl() + 'delete_lp_item', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'token': localStorage.getItem("ppvd_token"),
        },
        body: JSON.stringify({
          'file': this.props.match.params.file,
          'id': this.state.offerList[index].id,
        })
      })
        .then(response => response.json())
        .then(json => {
          if ('success' in json && json.success) {
            var temp = this.state.offerList;
            temp.splice(index, 1);
            this.setState({
              offerList: temp
            });
          }
          else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  goReport() {
    this.props.history.push('/report/' + this.props.match.params.file);
  }

  handleChangeOfferListCommission(index, event) {
    var temp = this.state.offerList;
    temp[index].commission = event.target.value;
    this.setState({
      offerList: temp
    });
  }

  handleChangeOfferListName(index, event) {
    var temp = this.state.offerList;
    temp[index].title = event.target.value;
    this.setState({
      offerList: temp
    });
  }

  handleChangeOfferListUrl(index, event) {
    var temp = this.state.offerList;
    temp[index].url = event.target.value;
    this.setState({
      offerList: temp
    });
  }

  handleChangeOfferListWeight(index, event) {
    var temp = this.state.offerList;
    temp[index].weight = event.target.value;
    this.setState({
      offerList: temp
    });
  }

  switchShowInactive() {
    this.setState({
      showInactive: !this.state.showInactive,
    })
  }

  handleCPV(event) {
    this.setState({
      cpv: event.target.value,
    })
  }

  handleGroup(event) {
    this.setState({
      group: event.target.value,
    })
  }

  handleOffer(event) {
    this.setState({
      offer: event.target.value,
    })
  }

  handleCommission(event) {
    this.setState({
      commission: event.target.value,
    })
  }

  handleOfferUrl(event) {
    this.setState({
      offerUrl: event.target.value,
    })
  }

  handleOfferWeight(event) {
    this.setState({
      offerWeight: event.target.value,
    })
  }

  render() {
    var offerListItems = [];
    var offerList = this.state.offerList;
    this.offerWeightSum = 0;
    for (var index = 0; index < offerList.length; index++) {
      var item = offerList[index];
      if (item.hidden === 0 || (this.state.showInactive && item.hidden === 1)) {
        this.offerWeightSum += item.weight;
        offerListItems.push(
          <ListGroup flush className="custom-block-border" key={index}>
            <ListGroupItem className="p-3">
              <Row>
                <Col md={8}>
                  <FormGroup>
                    <Row form>
                      <label className="col-md-2 col-form-label label-responsive">Offer:</label>
                      <Col md={10}>
                        <FormInput onChange={this.handleChangeOfferListName.bind(null, index)} value={item.title} />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row form>
                      <label className="col-md-2 col-form-label label-responsive">URL:</label>
                      <Col md={10}>
                        <FormInput onChange={this.handleChangeOfferListUrl.bind(null, index)} value={item.url} />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row form>
                      <label className="col-md-2 col-form-label label-responsive">Commission($):</label>
                      <Col md={10}>
                        <FormInput onChange={this.handleChangeOfferListCommission.bind(null, index)} value={item.commission} />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row form>
                      <label className="col-md-2 col-form-label label-responsive">Weight(%):</label>
                      <Col md={10}>
                        <FormInput onChange={this.handleChangeOfferListWeight.bind(null, index)} value={item.weight} />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <Row>
                    <Col className="text-center">
                      <span>Actions</span>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col className="text-center">
                      <Button variant="contained" color="primary" className="copy-button m-1" onClick={this.onSaveOfferItem.bind(null, index)}>SAVE</Button>
                      <Button variant="contained" color="primary" className="copy-button m-1" onClick={this.onSwitchOfferItem.bind(null, index)}>{item.hidden === 0 ? 'HIDE' : 'SHOW'}</Button>
                      <Button variant="contained" color="primary" className="copy-button m-1" onClick={this.onDeleteOfferItem.bind(null, index)}>DELETE</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        )
      }
    }
    
    return (
      <Container fluid className="main-content-container p-4">
        <Row className="justify-content-center">
          <Col>
            <Card small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">Navigation</h6>
              </CardHeader>
              <CardBody className="p-2 text-center">
                <Button variant="contained" color="primary" className="save-button m-1" onClick={this.switchShowInactive}>{this.state.showInactive ? 'Hide Inactive' : 'Show All'}</Button>
                <Button variant="contained" color="primary" className="save-button m-1" onClick={this.goReport}>Campaign Report</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col md={6}>
            <Card small>
              <CardHeader className="border-bottom">
                <h6 className="m-0"> {this.props.match.params.file} Settings</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Row form>
                          <label className="col-12 col-form-label">CPV :</label>
                          <Col>
                            <FormInput onChange={this.handleCPV} />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-12 col-form-label">Group :</label>
                          <Col>
                            <FormInput onChange={this.handleGroup} />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col className="text-right">
                            <Button variant="contained" color="primary" className="save-button" onClick={this.settingsSave}>Save</Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
          <Col md={6} >
            <Card small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">Add Offer Url</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Row form>
                          <label className="col-12 col-form-label">Offer:</label>
                          <Col>
                            <FormInput onChange={this.handleOffer} />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-12 col-form-label">Commission$:</label>
                          <Col>
                            <FormInput onChange={this.handleCommission} />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-12 col-form-label">URL: http://</label>
                          <Col>
                            <FormInput onChange={this.handleOfferUrl} />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-12 col-form-label">Weight  %:</label>
                          <Col>
                            <FormInput onChange={this.handleOfferWeight} />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col className="text-right">
                            <Button variant="contained" color="primary" className="save-button" onClick={this.offerCreate}>Create</Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col>
            <Card small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">Postback</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                    <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Postback URL:</label>
                          <Col md={8}>
                            <FormInput readOnly value={getApiUrl() + 'track_link?file=' + this.props.match.params.file + '&sub1=' + this.state.platform.sub1 + '&sub2=' + this.state.platform.sub2 + '&sub3=' + this.state.platform.sub3 + '&sub4=' + this.state.platform.sub4 + '&sub5=' + this.state.platform.sub5} />
                          </Col>
                          <CopyToClipboard text={getApiUrl() + 'track_link?file=' + this.props.match.params.file + '&sub1=' + this.state.platform.sub1 + '&sub2=' + this.state.platform.sub2 + '&sub3=' + this.state.platform.sub3 + '&sub4=' + this.state.platform.sub4 + '&sub5=' + this.state.platform.sub5}>
                            <Button variant="contained" color="primary" className="copy-button">Copy</Button>
                          </CopyToClipboard>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">{'Add this php code at the top of action what you want to track. NOTE: Only works on .php pages!'}</label>
                          <Col md={8}>
                            <FormTextarea readOnly value={"<?php\nfile_get_contents('" + getApiUrl() + 'track_link?file=' + this.props.match.params.file + '&sub1=' + this.state.platform.sub1 + '&sub2=' + this.state.platform.sub2 + '&sub3=' + this.state.platform.sub3 + '&sub4=' + this.state.platform.sub4 + '&sub5=' + this.state.platform.sub5 +"');\n?>"} className="custom-form-big-textarea" />
                          </Col>
                          <CopyToClipboard text={"<?php\nfile_get_contents('" + getApiUrl() + 'track_link?file=' + this.props.match.params.file + '&sub1=' + this.state.platform.sub1 + '&sub2=' + this.state.platform.sub2 + '&sub3=' + this.state.platform.sub3 + '&sub4=' + this.state.platform.sub4 + '&sub5=' + this.state.platform.sub5 +"');\n?>"}>
                            <Button variant="contained" color="primary" className="copy-button">Copy</Button>
                          </CopyToClipboard>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col>
            <Card small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">Javascript Code:</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">{'Add this javscript code at the page what you want to track.'}</label>
                          <Col md={8}>
                            <FormTextarea readOnly value={"<script type='text/javascript'>\nvar xhttp = new XMLHttpRequest();\nxhttp.open('GET', '" + getApiUrl() + 'track_link?file=' + this.props.match.params.file + '&sub1=' + this.state.platform.sub1 + '&sub2=' + this.state.platform.sub2 + '&sub3=' + this.state.platform.sub3 + '&sub4=' + this.state.platform.sub4 + '&sub5=' + this.state.platform.sub5 +"', true);\nxhttp.send();\n</script>"} className="custom-form-big-textarea" />
                          </Col>
                          <CopyToClipboard text={"<script type='text/javascript'>\nvar xhttp = new XMLHttpRequest();\nxhttp.open('GET', '" + getApiUrl() + 'track_link?file=' + this.props.match.params.file + '&sub1=' + this.state.platform.sub1 + '&sub2=' + this.state.platform.sub2 + '&sub3=' + this.state.platform.sub3 + '&sub4=' + this.state.platform.sub4 + '&sub5=' + this.state.platform.sub5 +"', true);\nxhttp.send();\n</script>"}>
                            <Button variant="contained" color="primary" className="copy-button">Copy</Button>
                          </CopyToClipboard>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col>
            <Card small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">Image Pixel:</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Conversion Pixel:</label>
                          <Col md={8}>
                          <FormInput readOnly value={'<img width="0" height="0" style="visibility:hidden" src="' + getApiUrl() + 'conv?file=' + this.props.match.params.file + '&data_id=<?php echo isset($_GET["data_id"]) ? trim($_GET["data_id"]) : ""; ?>" />'} />
                          </Col>
                          <CopyToClipboard text={'<img width="0" height="0" style="visibility:hidden" src="' + getApiUrl() + 'conv?file=' + this.props.match.params.file + '&data_id=<?php echo isset($_GET["data_id"]) ? trim($_GET["data_id"]) : ""; ?>" />'}>
                            <Button variant="contained" color="primary" className="copy-button">Copy</Button>
                          </CopyToClipboard>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col>
            <Card small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">Tracking</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Tracking URL:</label>
                          <Col md={8}>
                            <FormInput readOnly value={getApiUrl() + 'link?file=' + this.props.match.params.file + '&sub1=' + this.state.platform.sub1 + '&sub2=' + this.state.platform.sub2 + '&sub3=' + this.state.platform.sub3 + '&sub4=' + this.state.platform.sub4 + '&sub5=' + this.state.platform.sub5} />
                          </Col>
                          <CopyToClipboard text={getApiUrl() + 'link?file=' + this.props.match.params.file + '&sub1=' + this.state.platform.sub1 + '&sub2=' + this.state.platform.sub2 + '&sub3=' + this.state.platform.sub3 + '&sub4=' + this.state.platform.sub4 + '&sub5=' + this.state.platform.sub5}>
                            <Button variant="contained" color="primary" className="copy-button">Copy</Button>
                          </CopyToClipboard>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <Button variant="contained" color="primary" className="copy-button" onClick={this.onTestLink}>TEST LINK</Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col>
            <Card small>
              <CardHeader className="border-bottom">
                <Row className="pl-3 mb-4">
                  <Col>
                    {((this.offerWeightSum < 100 || this.offerWeightSum >= 101) && offerListItems.length > 0) &&
                      <MySnackbarContentWrapper
                        variant="error"
                        message="Your url weight percentages must add up to 100% for all urls.Offers will evenly rotate until the sum is corrected!"
                      />
                    }
                  </Col>
                </Row>
                <Row className="pl-3">
                  <h6 className="m-0">Offer List</h6>
                  <Col className="text-right">
                    <Button variant="contained" color="primary" className="copy-button m-1" onClick={this.showAllOfferItems}>ACTIVE ALL</Button>
                    <Button variant="contained" color="primary" className="copy-button m-1" onClick={this.hideAllOfferItems}>DEACTIVE ALL</Button>
                    <Button variant="contained" color="primary" className="copy-button m-1" onClick={this.splitOfferItems}>EVEN SPLIT</Button>
                  </Col>
                </Row>
              </CardHeader>
              {offerListItems}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default EditCampaign;