import React from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  Container,
  DatePicker,
} from "shards-react";
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../components/pagination/TablePaginationActions';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { getApiUrl } from './Global';

const checkBoxStyles = theme => ({
  root: {
    '&$checked': {
      color: '#1AB394',
    },
  },
  checked: {},
})

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const periodNames = [
  'Today',
  'Yesterday',
  'This week',
  'Last week',
  'Last 7days',
  'This month',
  'Last month',
  'All',
];

const convert_Date_to_String = (tmpDate) => {
  var res = ("" + tmpDate.getFullYear() + "-");
  var month = tmpDate.getMonth() + 1;
  var date = tmpDate.getDate(); 
  if(month < 10) res = (res + "0" + month + "-");
  else res = (res + month + "-");
  if(date < 10) res = (res + "0" + date);
  else res = (res + date);

  return res;
}

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lpreportList: [
        {
          views: 0,
        },
        {
          views: 10,
        },
        {
          views: 0,
        },
        {
          views: 0,
        },
        {
          views: 0,
          clicks: 0
        },
        {
          views: 0,
        },
        {
          views: 0,
        },
        {
          views: 0,
        },
      ],
      detailedData: [],
      startDate: new Date(),
      endDate: new Date(),
      rowsPerPage: 5,
      page: 0,
      sub1Added: false,
      sub2Added: false,
      sub3Added: false,
      sub4Added: false,
      sub5Added: false,
      idAdded: false,
      titleAdded: true,
      urlAdded: false,
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleSub1Added = this.handleSub1Added.bind(this);
    this.handleTitleAdded = this.handleTitleAdded.bind(this);
    this.handleSub2Added = this.handleSub2Added.bind(this);
    this.handleSub3Added = this.handleSub3Added.bind(this);
    this.handleSub4Added = this.handleSub4Added.bind(this);
    this.handleSub5Added = this.handleSub5Added.bind(this);
    this.handleIDAdded = this.handleIDAdded.bind(this);
    this.handleUrlAdded = this.handleUrlAdded.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.changeCustomPeriod = this.changeCustomPeriod.bind(this);
    this.handleQuickPeriod = this.handleQuickPeriod.bind(this);
    this.editCampaign = this.editCampaign.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.clearConv = this.clearConv.bind(this);
  }

  componentDidMount() {
    if (!localStorage.getItem("ppvd_token"))
      this.props.history.push("login");
    this.refreshPage();
  }

  clearAll() {
    if(window.confirm("Are you want to clear all data?") === true) {
      fetch(getApiUrl() + 'clear_all_report_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'token': localStorage.getItem("ppvd_token"),
        },
        body: JSON.stringify({
          'file': this.props.match.params.file,
        })
      })
        .then(response => response.json())
        .then(json => {
          if ('success' in json && json.success) {
            this.refreshPage();
          }
          else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  clearConv() {
    if(window.confirm("Are you want to clear all conv?") === true) {
      fetch(getApiUrl() + 'clear_all_report_conv', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'token': localStorage.getItem("ppvd_token"),
        },
        body: JSON.stringify({
          'file': this.props.match.params.file,
        })
      })
        .then(response => response.json())
        .then(json => {
          if ('success' in json && json.success) {
            var tmpDetailedData = this.state.detailedData;
            for(var i = 0; i <  tmpDetailedData.length; i++)
              tmpDetailedData[i].conv = 0;
            this.setState({
              detailedData: tmpDetailedData,
            })
          }
          else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  refreshPage() {
    fetch(getApiUrl() + 'get_report_period_data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        'file': this.props.match.params.file,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          this.setState({
            lpreportList: json.increment,
          })
        }
        else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.getTableData();
  }

  editCampaign() {
    this.props.history.push('/edit-campaign/' + this.props.match.params.file);
  }

  handleQuickPeriod = async(name) => {
    var startDate, endDate;
    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    var d = new Date();
    var thisMonday = new Date(d.setDate(d.getDate() - d.getDay() + (d.getDay() === 0 ? -6:1)));
    var lastMonday = new Date(thisMonday);
    lastMonday.setDate(thisMonday.getDate() - 7);
    var lastSunday = new Date(thisMonday);
    lastSunday.setDate(thisMonday.getDate() - 1);
    var last7days = new Date(today);
    last7days.setDate(today.getDate() - 7);
    var startMonth = new Date(today);
    startMonth.setDate(1);
    var endLastMonth = new Date(startMonth);
    endLastMonth.setDate(startMonth.getDate() - 1);
    var startLastMonth = new Date(endLastMonth);
    startLastMonth.setDate(1);
    var startAll = new Date("2010-01-01");

    switch(name) {
      case 'Today':
        startDate = new Date();
        endDate = new Date();
        break;
      case 'Yesterday':
        startDate = yesterday;
        endDate = yesterday;
        break;
      case   'This week':
        startDate = thisMonday;
        endDate = today;
        break;
      case 'Last week':
        startDate = lastMonday;
        endDate = lastSunday;
        break;
      case 'Last 7days':
        startDate = last7days;
        endDate = today;
        break;
      case 'This month':
        startDate = startMonth;
        endDate = today;
        break;
      case 'Last month':
        startDate = startLastMonth;
        endDate = endLastMonth;
        break;
      case 'All':
        startDate = startAll;
        endDate = today;
        break;
      default:
    }
    await this.setState({
      startDate: startDate,
      endDate: endDate,
    })
    this.getTableData();
  }

  changeCustomPeriod() {
    this.getTableData();
  }

  getTableData() {
    fetch(getApiUrl() + 'get_report_table_data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        'file': this.props.match.params.file,
        'start_date' : convert_Date_to_String(this.state.startDate),
        'end_date' : convert_Date_to_String(this.state.endDate),
        'sub1' : this.state.sub1Added,
        'sub2' : this.state.sub2Added,
        'sub3' : this.state.sub3Added,
        'sub4' : this.state.sub4Added,
        'sub5' : this.state.sub5Added,
        'id': this.state.idAdded,
        'title': this.state.titleAdded,
        'url': this.state.urlAdded,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          this.setState({
            detailedData: json.detail_data,
          })
        }
        else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleTitleAdded = async() => {
    await this.setState({
      titleAdded: !this.state.titleAdded,
    });
    this.getTableData();
  }

  handleUrlAdded = async() => {
    await this.setState({
      urlAdded: !this.state.urlAdded,
    });
    this.getTableData();
  }

  handleSub1Added = async() => {
    await this.setState({
      sub1Added: !this.state.sub1Added,
    });
    this.getTableData();
  }

  handleIDAdded = async() => {
    await this.setState({
      idAdded: !this.state.idAdded,
    });
    this.getTableData();
  }

  handleSub2Added = async() => {
    await this.setState({
      sub2Added: !this.state.sub2Added,
    });
    this.getTableData();
  }

  handleSub3Added = async() => {
    await this.setState({
      sub3Added: !this.state.sub3Added,
    });
    this.getTableData();
  }

  handleSub4Added = async() => {
    await this.setState({
      sub4Added: !this.state.sub4Added,
    });
    this.getTableData();
  }

  handleSub5Added = async() => {
    await this.setState({
      sub5Added: !this.state.sub5Added,
    });
    this.getTableData();
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    })
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    })
  };

  handleStartDateChange = date => {
    this.setState({
      startDate: date,
    });
  };

  handleEndDateChange = date => {
    this.setState({
      endDate: date,
    });
  };

  render() {
    var detailedData = this.state.detailedData;
    var detailDataItems = [];
    var detailDataTotal = {
      views: 0,
      clicks: 0,
      ctr: 0,
      hits: 0,
      conv: 0,
      percent: 0,
      rev: 0,
      cost: 0,
      profit: 0,
      epc: 0,
      cpc: 0,
    };
    var detailDataPart;
    if(this.state.rowsPerPage === -1)
      detailDataPart = detailedData;
    else  
      detailDataPart = detailedData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage);
  
    for(var i = 0; i < detailDataPart.length; i++) {
      detailDataItems.push(
        <TableRow key={i} hover>
          {this.state.idAdded &&
            <TableCell align="center" className="custom-cell">{detailDataPart[i].id}</TableCell>
          }
          {this.state.idAdded &&
            <TableCell align="center" className="custom-cell">{detailDataPart[i].date}</TableCell>
          }
          {this.state.lpNameAdded &&
            <TableCell align="center" className="custom-cell">{detailDataPart[i].lp_name}</TableCell>
          }
          {this.state.sub1Added &&
            <TableCell align="center" className="custom-cell">{detailDataPart[i].sub1}</TableCell>
          }
          {this.state.sub2Added &&
            <TableCell align="center" className="custom-cell">{detailDataPart[i].sub2}</TableCell>
          }
          {this.state.sub3Added &&
            <TableCell align="center" className="custom-cell">{detailDataPart[i].sub3}</TableCell>
          }
          {this.state.sub4Added &&
            <TableCell align="center" className="custom-cell">{detailDataPart[i].sub4}</TableCell>
          }
          {this.state.sub5Added &&
            <TableCell align="center" className="custom-cell">{detailDataPart[i].sub5}</TableCell>
          }
          <TableCell align="center" className='custom-cell '>{detailDataPart[i].views}</TableCell>
          <TableCell align="center" className='custom-cell '>{detailDataPart[i].conv}</TableCell>
          <TableCell align="center" className='custom-cell '>{Math.round(detailDataPart[i].percent * 100) / 100}</TableCell>
          <TableCell align="center" className='custom-cell '>{Math.round(detailDataPart[i].rev * 100) / 100}</TableCell>
          <TableCell align="center" className='custom-cell '>{Math.round(detailDataPart[i].cost * 100) / 100}</TableCell>
          <TableCell align="center" className={'custom-cell ' + (detailDataPart[i].profit < 0 ? 'red-table-cell' : '')}>{Math.round(detailDataPart[i].profit * 100) / 100}</TableCell>
          <TableCell align="center" className='custom-cell '>{Math.round(detailDataPart[i].epv * 100) / 100}</TableCell>
          <TableCell align="center" className='custom-cell '>{Math.round(detailDataPart[i].cpv * 100) / 100}</TableCell>
        </TableRow>
      )
      detailDataTotal.views += (detailDataPart[i].views * 1);
      detailDataTotal.conv += (detailDataPart[i].conv * 1);
      detailDataTotal.percent += (detailDataPart[i].percent * 1);
      detailDataTotal.rev += (detailDataPart[i].rev * 1);
      detailDataTotal.cost += (detailDataPart[i].cost * 1);
      detailDataTotal.profit += (detailDataPart[i].profit * 1);
      detailDataTotal.epc += (detailDataPart[i].epv * 1);
      detailDataTotal.cpc += (detailDataPart[i].cpv * 1);
    }

    return (
      <Container fluid className="main-content-container p-4">
        <Row className="justify-content-center">
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Navigation</h6>
              </CardHeader>
              <CardBody className="p-2 text-center">
                <Button variant="contained" color="primary" className="save-button m-1" onClick={this.refreshPage}>Refresh</Button>
                <Button variant="contained" color="primary" className="save-button m-1" onClick={this.editCampaign}>Edit Campaign</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* Report Table */}
        <Row className="mt-3">
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Summary Report for <span className="card-title-text">{this.props.match.params.file}</span></h6>
              </CardHeader>
              <CardBody className="p-3 pb-3">
                <Table className="custom-table" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className="custom-header-cell">Period</TableCell>
                      <TableCell align="center" className="custom-header-cell">Num. of Impressions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.lpreportList.map((row, index) => (
                      <TableRow key={index} >
                        <TableCell align="center" className={'custom-lp-cell ' + ((index % 2 === 1) ? 'odd-cell' : '')}>{periodNames[index]}</TableCell>
                        <TableCell align="center" className={'custom-lp-cell ' + ((index % 2 === 1) ? 'odd-cell' : '')}>{row.views}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Period Selection</h6>
              </CardHeader>
              <CardBody className="p-2 text-left">
                <Row>
                  <Col>
                    <span className="mr-2">{'Range Select:'}</span>
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={this.handleStartDateChange}
                    />
                    <span className="mr-2 ml-2">{'to:'}</span>
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={this.handleEndDateChange}
                    />
                    <Button variant="contained" color="primary" className="period-button ml-4" onClick={this.changeCustomPeriod}>Submit</Button>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <span className="mr-2">{'Quick Link:'}</span>
                    {periodNames.map((name, index) => (
                      <Button variant="contained" color="primary" className="period-button m-1" key={index} onClick={this.handleQuickPeriod.bind(null, name)}>{name}</Button>
                    ))}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Campaign Report - <span className="card-title-text">{this.props.match.params.file}</span></h6>
              </CardHeader>
              <CardBody className="p-3 pb-3">
                <FormGroup row className="justify-content-center">
                <FormControlLabel
                    control={
                      <CustomCheckbox checked={this.state.titleAdded} onChange={this.handleTitleAdded} color="default" value="title" />
                    }
                    label="Title"
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckbox checked={this.state.sub1Added} onChange={this.handleSub1Added} color="default" value="sub1" />
                    }
                    label="Sub1"
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckbox checked={this.state.sub2Added} onChange={this.handleSub2Added} color="default" value="sub2" />
                    }
                    label="Sub2"
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckbox checked={this.state.sub3Added} onChange={this.handleSub3Added} color="default" value="sub3" />
                    }
                    label="Sub3"
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckbox checked={this.state.sub4Added} onChange={this.handleSub4Added} color="default" value="sub4" />
                    }
                    label="Sub4"
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckbox checked={this.state.sub5Added} onChange={this.handleSub5Added} color="default" value="sub5" />
                    }
                    label="Sub5"
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckbox checked={this.state.idAdded} onChange={this.handleIDAdded} color="default" value="id" />
                    }
                    label="ID"
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckbox checked={this.state.urlAdded} onChange={this.handleUrlAdded} color="default" value="url" />
                    }
                    label="URL"
                  />
                </FormGroup>
                <Table className='custom-table ' aria-label="simple table" >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan="21" className="text-center position-relative custom-table-header-cell">
                        <h6 className="m-0 custom-table-title-text">Campaign Report - {this.props.match.params.file}</h6>
                        <div className="clear-action-box">
                          <Button className="custom-action-white-button" onClick={this.clearAll}>Clear all</Button>
                          <Button className="custom-action-white-button" onClick={this.clearConv}>Clear conv</Button>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                    {this.state.idAdded &&
                        <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>ID</h6></TableCell>
                      }
                      {this.state.idAdded &&
                        <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>Date</h6></TableCell>
                      }
                      {this.state.lpNameAdded &&
                        <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>LP Name</h6></TableCell>
                      }
                      {this.state.sub1Added &&
                        <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>sub1</h6></TableCell>
                      }
                      {this.state.sub2Added &&
                        <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>sub2</h6></TableCell>
                      }
                      {this.state.sub3Added &&
                        <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>sub3</h6></TableCell>
                      }
                      {this.state.sub4Added &&
                        <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>sub4</h6></TableCell>
                      }
                      {this.state.sub5Added &&
                        <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>sub5</h6></TableCell>
                      }
                      <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>Views</h6></TableCell>
                      <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>Conv</h6></TableCell>
                      <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>%</h6></TableCell>
                      <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>Rev</h6></TableCell>
                      <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>Cost</h6></TableCell>
                      <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>Profit</h6></TableCell>
                      <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>EPV</h6></TableCell>
                      <TableCell align="center" className="custom-cell"><h6 style={{ fontWeight: 'bold' }}>CPV</h6></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detailDataItems}
                    <TableRow hover>
                    {this.state.idAdded &&
                        <TableCell align="center" className='custom-cell total-cell'></TableCell>
                      }
                      {this.state.idAdded &&
                        <TableCell align="center" className='custom-cell total-cell'></TableCell>
                      }
                      {this.state.lpNameAdded &&
                        <TableCell align="center" className='custom-cell total-cell'></TableCell>
                      }
                      {this.state.sub1Added &&
                        <TableCell align="center" className='custom-cell total-cell'></TableCell>
                      }
                      {this.state.sub2Added &&
                        <TableCell align="center" className='custom-cell total-cell'></TableCell>
                      }
                      {this.state.sub3Added &&
                        <TableCell align="center" className='custom-cell total-cell'></TableCell>
                      }
                      {this.state.sub4Added &&
                        <TableCell align="center" className='custom-cell total-cell'></TableCell>
                      }
                      {this.state.sub5Added &&
                        <TableCell align="center" className='custom-cell total-cell'></TableCell>
                      }
                      <TableCell align="center" className='custom-cell total-cell'>{detailDataTotal.views}</TableCell>
                      <TableCell align="center" className='custom-cell total-cell'>{detailDataTotal.conv}</TableCell>
                      <TableCell align="center" className='custom-cell total-cell'>{detailDataTotal.percent}</TableCell>
                      <TableCell align="center" className='custom-cell total-cell'>{detailDataTotal.rev}</TableCell>
                      <TableCell align="center" className='custom-cell total-cell'>{detailDataTotal.cost}</TableCell>
                      <TableCell align="center" className={'custom-cell total-cell ' + (detailDataTotal.profit < 0 ? 'red-table-cell' : '')}>{Math.round(detailDataTotal.profit * 100) / 100}</TableCell>
                      <TableCell align="center" className='custom-cell total-cell'>{detailDataTotal.epc}</TableCell>
                      <TableCell align="center" className='custom-cell total-cell'>{detailDataTotal.cpc}</TableCell>
                      <TableCell align="center" className='custom-cell total-cell'></TableCell>
                    </TableRow>
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100, { label: 'All', value: -1 }]}
                        colSpan={21}
                        count={detailedData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Report;